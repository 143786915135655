<template>
  <div class="">
    <ul class="tablist">
      <li class="tabmenu current" @click="goPageByName('point')">{{$t('front.point.pointHistory')}}</li>
      <li class="tabmenu" @click="goPageByName('pointuse')">{{$t('front.point.pointChangeApply')}}</li>
      <li class="tabmenu" @click="goPageByName('pointUselist')">{{$t('front.point.convertPoint')}}</li>
    </ul>
    <!--ul class="strbtnPT lastnone mb20">
      <li>{{$t('front.point.leftPoint')}}<span>{{thousand(sum ? sum.pointAmt: 0)}} P</span></li>
      <li>{{$t('front.point.monthPoint')}}<span>{{thousand(sum ? sum.thisMonthPoint : 0)}} P</span></li>
      <li>{{$t('front.point.prevMonthPoint')}}<span>{{thousand(sum ? sum.preMonthPoint: 0)}} P</span></li>
      <li>{{$t('front.point.allPoint')}}<span>{{thousand(sum ? sum.totalInPoint : 0)}} P</span></li>
      <li>{{$t('front.point.allUsePoint')}}<span>{{thousand(sum ? sum.totalOutPoint : 0)}} P</span></li>
      <li></li>
    </ul-->
    <div class="PTsch">
     <div class="datesearchPT">
        <date-filter @search="loadList(1)"
                     @update="onChangeDate"
                     :startDate="searchDate.startDate"
                     :endDate="searchDate.endDate" />
     </div>
     <div class="datesearchM">
        <date-filter-mobile @search="loadList"
                     @update="onChangeDate" />
     </div>
    </div>

    <div class="strTablescr">
     <div class="strTablePC">
        <table class="strTablePT">
          <colgroup>
            <col width="10%">
            <col width="10%">
            <col width="10%">
            <col width="15%">
            <col width="15%">
            <col width="15%">
            <col width="25%">
          </colgroup>
          <thead>
            <tr>
              <th>{{$t('front.board.accrualDay')}}</th>
              <th>{{$t('front.common.memId')}}</th>
              <th>{{$t('front.board.kind')}}</th>
              <th>{{$t('front.board.betMoney')}}</th>
              <th>{{$t('front.board.percent')}}</th>
              <th>{{$t('front.board.bonusPercent')}}</th>
              <th>{{$t('front.board.accrualPoint')}}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="list.length > 0">
              <template v-for="item in list" :key="item.gameIdx">
                <tr>
                  <td>{{dateFormatAll(item.regDt)}}</td>
                  <td>{{item.memId}}</td>
                  <td>{{item.codeName}}</td>
                  <td>{{thousand(item.betAmt)}}</td>
                  <td>{{item.pointRate}}%</td>
                  <td>{{item.pointBonusRate}}%</td>
                  <td>{{thousand(item.point_inAmt)}}P</td>
                </tr>
              </template>
            </template>
            <template v-else>
              <tr>
              </tr>
            </template>
          </tbody>
        </table>
     </div>
     <div class="strTableM">
        <div class="strTablePTM">
           <template v-if="list.length > 0">
             <template v-for="item in list" :key="item.gameIdx">
               <ul>
                 <li><em>{{$t('front.board.accrualDay')}}</em>{{dateFormatAll(item.regDt)}}</li>
                 <li><em>{{$t('front.common.memId')}}</em>{{item.memId}}</li>
                 <li><em>{{$t('front.board.kind')}}</em>{{item.codeName}}</li>
                 <li><em>{{$t('front.board.betMoney')}}</em>{{thousand(item.betAmt)}}</li>
                 <li><em>{{$t('front.board.percent')}}</em>{{item.pointRate}}%</li>
                 <li><em>{{$t('front.board.bonusPercent')}}</em>{{item.pointBonusRate}}%</li>
                 <li><em>{{$t('front.board.accrualPoint')}}</em>{{thousand(item.point_inAmt)}}P</li>
               </ul>
             </template>
           </template>
           <template v-else>
             <ul>
                <li class="nodata">{{$t('front.common.notFoundList')}}</li>
             </ul>
           </template>
        </div>
     </div>
  </div>
    <pagination
        :pageNum="pageInfo.page"
        :pageSize="pageInfo.count_per_list"
        :totalCount="pageInfo.tatal_list_count"
        @goToPage="loadList" />

  </div>
</template>

<script>
import DateFilter from '@/components/ui/DateFilter'
import DateFilterMobile from '@/components/ui/DateFilterMobile'
import Pagination from '@/components/ui/Pagination'

import { getPointList } from '@/api/point'
export default {
  name: 'pointList',
  components: { DateFilter, DateFilterMobile, Pagination },
  data () {
    return {
      list: [],
      sum: {}
    }
  },
  created () {
    this.loadList()
  },
  methods: {
    loadList (page) {
      const params = {
        pointType: 'in',
        page: page || this.pageInfo.page,
        startDate: this.searchDate.startDate,
        endDate: this.searchDate.endDate
      }

      getPointList(params).then(response => {
        const result = response.data
        if (result.resultCode === '0') {
          if (result.data.pageInfo) {
            this.pageInfo = result.data.pageInfo
          }
          const data = result.data
          this.list = data.list
          this.sum = data.sum
        }
      })
    }
  }
}
</script>
<style scoped>
.boardw li:nth-child(2){word-break: break-all;}
</style>
<style src="@/styles/striNew.css"></style>
